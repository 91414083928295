<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      append-to-body
      width="40vw"
      :before-close="handleClose"
      class="roll-dialog"
    >
      <div class="title">{{ $t(`estate.extractdDialog.title`) }}</div>
      <div class="content">
        <div class="formData">
          <el-form
            :model="formData"
            :rules="rules"
            ref="formData"
            label-width="0"
          >
            <el-form-item label="" prop="chainId">
              <span class="formData-label">{{
                $t(`estate.extractdDialog.chainId`)
              }}</span>
              <el-select
                v-model="formData.chainId"
                filterable
                placeholder=""
                style="width: 100%"
              >
<!--                <el-option label="Umarket" value="4" />-->
                <el-option label="BNB Smart Chain(BEP20)" value="2" />
                <el-option label="Ethereum(ERC20)" value="1" />
                <el-option label="Polygon" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="address">
              <span class="formData-label">{{
                $t(`estate.extractdDialog.address`)
              }}</span>
              <el-input v-model="formData.address"></el-input>
            </el-form-item>
            <el-form-item label="" prop="amount" style="width: 45%">
              <span class="formData-label">{{
                $t(`estate.extractdDialog.amount`)
              }}</span>
              <!-- <el-input-number
                v-model="formData.amount"
                :controls="false"
                :min="0"
                style="width: 100%"
              /> -->
              <el-input
                v-model="formData.amount"
                @input="amountInpoutChange"
              ></el-input>
            </el-form-item>
            <div class="formData-block">
              <el-form-item
                label=""
                style="display: inline-block; width: 45%; margin-right: 10%"
              >
                <span class="formData-label">{{
                  $t(`estate.extractdDialog.serviceCharge`)
                }}</span>
                <el-input v-model="formData.serviceCharge" disabled />
              </el-form-item>
              <el-form-item label="" style="display: inline-block; width: 45%">
                <span class="formData-label">{{
                  $t(`estate.extractdDialog.toTheAccount`)
                }}</span>
                <el-input v-model="formData.toTheAccount" disabled />
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="content-info">
          <div class="content-info-title">
            <span class="mr">*</span>
            <span>{{ $t(`estate.extractdDialog.warnTitle`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.extractdDialog.warninfo1`)+txMin+'USDT' }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.extractdDialog.warninfo2`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.extractdDialog.warninfo3`) }}</span>
          </div>
          <div class="content-info-info">
            <span class="mr">•</span>
            <span>{{ $t(`estate.extractdDialog.warninfo4`) }}</span>
          </div>
        </div>
      </div>
      <el-button type="primary" class="dialog-footer" @click="subData">{{
        $t(`estate.extractdDialog.subBtn`)
      }}</el-button>
      <el-dialog
        top="18%"
        width="41vw"
        :visible.sync="innerVisible"
        append-to-body
        :show-close="false"
      >
        <el-form
          :model="formData"
          :rules="rules"
          ref="pwdFormData"
          label-width="0"
        >
          <el-form-item label="" prop="tradePass">
            <span class="formData-label">{{
              $t(`estate.extractdDialog.tradePass`)
            }}</span>
            <el-input v-model="formData.tradePass" show-password></el-input>
          </el-form-item>
          <el-form-item label="" prop="fundPassword">
            <span class="formData-label">{{
              $t(`estate.extractdDialog.fundPassword`)
            }}</span>
            <el-input v-model="formData.fundPassword" show-password></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="dialog-footer" @click="pwdSub">{{
          $t(`estate.extractdDialog.withdraw`)
        }}</el-button>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { withdraw } from "@/api/estate";
export default {
  name: "acquireDialog",
  props: {
    txMin: {
      type: Number,
      default: 100
    }
  },
  data() {
    const formRulesCheck = (rule, value, callback) => {
      if (value) {
        if (rule.field == "amount") {
          if (isNaN(Number(value)) || value < this.txMin) {
            return callback(
              new Error(this.$t("estate.extractdDialog.rules.isNaN")+this.txMin)
            );
          }
          if (value > this.parentInfo.balance) {
            return callback(
              new Error(this.$t("estate.extractdDialog.rules.balance"))
            );
          }
        }
        callback();
      } else {
        if (value) {
          callback();
        } else {
          return callback(
            new Error(this.$t("estate.extractdDialog.rules." + rule.field))
          );
        }
      }
    };
    return {
      tooltipList: [],
      dialogVisible: false,
      innerVisible: false,
      parentInfo: {},
      formData: {
        chainId: "2",
        address: "",
        amount: undefined,
        serviceCharge: "",
        toTheAccount: "",
        tradePass: "",
        fundPassword: "",
      },
      rules: {
        chainId: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        address: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        amount: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        serviceCharge: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        toTheAccount: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        tradePass: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
        fundPassword: {
          required: true,
          validator: formRulesCheck,
          trigger: "blur",
        },
      },
    };
  },
  watch: {
    innerVisible(newval, oldval) {
      if (newval == false) {
        this.$refs["pwdFormData"].resetFields();
      }
    },
    immediate: true,
    deep: true,
  },
  methods: {
    pwdSub() {
      this.$refs["pwdFormData"].validate((valid) => {
        if (valid) {
          let data = this.formData;
          withdraw(data).then((res) => {
            if (res.code == 200) {
              this.resetData();
              this.$tooltipPop.open(null, true, {
                type: "success",
                info: [this.$t("estate.extractdDialog.subsuccess")],
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    subData() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.innerVisible = true;
        } else {
          return false;
        }
      });
    },
    opendialog(userInfo) {
      this.parentInfo = userInfo;
      this.dialogVisible = !this.dialogVisible;
    },
    // 提现数量不能大于剩余金额（balance），
    // 手续费计算是输入金额 * 费率（withDrawMFeeRate），在比较 算出来的费用小于最小数量（withDrawMinFee），那手续费就是最小数量，如果大于最高收费（withDrawMaxFee）就取最高手续费，
    // 到账数量输入的金额 - 手续费
    amountInpoutChange(val) {
      if (val == "") {
        this.formData.serviceCharge = "";
        this.formData.toTheAccount = "";
        return;
      }
      // 手续费计算
      this.formData.serviceCharge =
        Number(val) * Number(this.parentInfo.withDrawMFeeRate);
      if (this.formData.serviceCharge < this.parentInfo.withDrawMinFee) {
        this.formData.serviceCharge = this.parentInfo.withDrawMinFee;
      }
      if (this.formData.serviceCharge > this.parentInfo.withDrawMaxFee) {
        this.formData.serviceCharge = this.parentInfo.withDrawMaxFee;
      }
      this.formData.toTheAccount =
        this.formData.amount - this.formData.serviceCharge;
    },
    handleClose(done) {
      done();
      this.resetData();
    },
    resetData() {
      this.$refs["formData"].resetFields();
      this.$refs["pwdFormData"].resetFields();
      this.dialogVisible = false;
      this.innerVisible = false;
    },
  },
};
</script>
<style scoped lang='scss'>
.title {
  text-align: center;
  color: #019393;
  font-size: 1.25vw;
  font-weight: bold;
}
.content {
  .formData-label {
    margin-left: 1vw;
  }
  .content-info {
    margin-top: 0.6vw;
    .mr {
      margin-right: 0.4vw;
    }
    .content-info-title {
      font-size: 0.72917vw;
      font-weight: bold;
      margin-bottom: 0.2vw;
    }
    .content-info-info {
      font-size: 0.72917vw;
    }
  }
}
.dialog-footer {
  width: 100%;
  text-align: center;
  margin-top: 3vw;
}
::v-deep .el-input-number .el-input__inner {
  text-align: left !important;
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
</style>
