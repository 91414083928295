import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ko from './ko';
import en from './en';
import zh_CN from './zh_CN';
import koLocale from 'element-ui/lib/locale/lang/ko';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import $store from '@/store';

Vue.use(VueI18n);

const messages = {
	ko: {
		...ko,
		...koLocale,
	},
	en: {
		...en,
		...enLocale,
	},
	zh_CN: {
		...zh_CN,
		...zhLocale,
	},
};

const lang = $store.state.common.i18n ? $store.state.common.i18n : navigator.language == 'zh-CN' ? 'zh_CN' : navigator.language
const i18n = new VueI18n({
	locale: lang,
	messages,
});

export default i18n;